import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles.css';
import ProductListPage from '../ProductListPage';


const InventoryPage = () => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const navigate = useNavigate();
  const [modelOpen, setModelOpen] = useState(false);

  const handleDone = async (event) => {
    event.preventDefault();
    const newProduct = { name, price, quantity };
    console.log('Sending product data:', newProduct); // Add logging here
    await fetch('https://gidadobrothers.onrender.com/api/products', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProduct),
    });
    setModelOpen(false);
  };

  
  return (
    <div>
      <header>
        <img src="logo.png" alt="Logo" className="logo" /> {/* Replace with your logo path */}
        <h1>Textile Store Inventory</h1>
        <button onClick={() => {setModelOpen(true);}}>Add Product</button>
      </header>

     {modelOpen && (
      <div className="modal-overlay">
      <div className="modal-content">
        <h1>Add Product</h1>
      <button className='modal-close' onClick={() => {setModelOpen(false);}}><h3><b>X</b></h3></button>
      <form className="inventory-form" onSubmit={handleDone}>
        <input
          type="text"
          placeholder="Product Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="number"
          placeholder="Product Price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="Quantity"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
        <button type="submit">Add Product</button>
      </form>
      </div>
      </div>
     )}
      <ProductListPage />
    </div>
  );
};

export default InventoryPage;
