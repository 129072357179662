import React from 'react';
import { useLocation } from 'react-router-dom';
import './receipt.css';

const ReceiptPage = () => {
  const location = useLocation();
  const { receipt } = location.state || {};

  const handlePrint = () => {
    window.print();
  };

  const currentDate = new Date();

  if (!receipt) {
    return <p>No receipt data available</p>;
  }

  return (
    <div className="receipt">
      <h1><b>GIDADO BROTHERS</b></h1>
      <p>Shop number: 20 Malam Ibrahim Mori Baba NIPOST Complex off Ahmadu Bello way, Wunti market, Bauchi, Bauchi state.</p>
      <h1>Sales Receipt</h1>
      <table className="receipt-table">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Quantity</th>
            <th>Price per Unit</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {receipt.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>{(item.salePrice / item.quantity).toFixed(2)}</td>
              <td>{item.salePrice.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="total-price">
        <b>Total: {receipt.reduce((total, item) => total + item.salePrice, 0).toFixed(2)}</b>
      </div>
      <p className='thankyou'><b>Thank you for coming!</b></p>
      <p>For inquiries contact:</p>
      <p>Phone number: <b>08132722493</b></p>
      <p>Email address: <b>gidadobrothers@gmail.com</b></p>
      <button onClick={handlePrint}>Print Receipt</button>
      <div className="receipt-footer">
        <p>{currentDate.toLocaleDateString()}<i> {currentDate.toLocaleTimeString()}</i></p>
      </div>
    </div>
  );
};

export default ReceiptPage;
