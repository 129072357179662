import React, { useState, useEffect } from 'react';
import './report.css'; // Assuming you have a separate CSS file for styling
import Header from './Header';
import Footer from './footer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO, isWithinInterval } from 'date-fns';

const SalesRecordsPage = () => {
  const [sales, setSales] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('today');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredSales, setFilteredSales] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await fetch('https://gidadobrothers.onrender.com/api/purchase');
        const data = await response.json();
        console.log('Fetched sales data:', data); // Log the data
        setSales(data);
      } catch (error) {
        console.error('Error fetching sales:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchExpenses = async () => {
      try {
        const response = await fetch('https://gidadobrothers.onrender.com/api/expenses');
        const data = await response.json();
        setExpenses(data);
      } catch (error) {
        console.log('Fetching error:', error);
      }
    };

    fetchSales();
    fetchExpenses();
  }, []);

  useEffect(() => {
    filterSales();
    filterExpenses();
  }, [filter, startDate, endDate, sales, expenses]);

  const filterSales = () => {
    const now = new Date();
    const filteredSales = sales.filter((sale) => {
      if (!sale.date) {
        return false;
      }
      const saleDate = parseISO(sale.date);
      switch (filter) {
        case 'today':
          return (
            saleDate.getDate() === now.getDate() &&
            saleDate.getMonth() === now.getMonth() &&
            saleDate.getFullYear() === now.getFullYear()
          );
        case 'this-week':
          const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
          const endOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 6));
          return saleDate >= startOfWeek && saleDate <= endOfWeek;
        case 'this-month':
          return (
            saleDate.getMonth() === now.getMonth() &&
            saleDate.getFullYear() === now.getFullYear()
          );
        case 'custom':
          return isWithinInterval(saleDate, { start: startDate, end: endDate });
        default:
          return true;
      }
    }).sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort sales by date in descending order

    setFilteredSales(filteredSales);
  };

  const filterExpenses = () => {
    const now = new Date();
    const filteredExpenses = expenses.filter((expense) => {
      if (!expense.created_at) {
        return false;
      }
      const expenseDate = new Date(expense.created_at);
      switch (filter) {
        case 'today':
          return (
            expenseDate.getDate() === now.getDate() &&
            expenseDate.getMonth() === now.getMonth() &&
            expenseDate.getFullYear() === now.getFullYear()
          );
        case 'this-week':
          const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
          const endOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 6));
          return isWithinInterval(expenseDate, { start: startOfWeek, end: endOfWeek });
        case 'this-month':
          return (
            expenseDate.getMonth() === now.getMonth() &&
            expenseDate.getFullYear() === now.getFullYear()
          );
        case 'custom':
          return isWithinInterval(expenseDate, { start: startDate, end: endDate });
        default:
          return true;
      }
    }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort expenses by date in descending order

    setFilteredExpenses(filteredExpenses);
  };

  const getTotalExpensesAmount = () => {
    if (filteredExpenses.length === 0) {
      return 0;
    }
    return filteredExpenses.reduce((total, expense) => total + parseFloat(expense.amount), 0);
  };

  const getTotalCostPrice = () => {
    if (filteredSales.length === 0) {
      return 0;
    }
    return filteredSales.reduce((total, sale) => total + parseFloat(sale.cost_price), 0);
  };

  const getTotalSalePrice = () => {
    if (filteredSales.length === 0) {
      return 0;
    }
    return filteredSales.reduce((total, sale) => total + parseFloat(sale.sale_price), 0);
  };

  const getTotalProfit1 = () => {
    if (filteredSales.length === 0) {
      return 0;
    }
    return filteredSales.reduce((total, sale) => total + parseFloat(sale.profit), 0);
  };

  const getTotalProfit = getTotalProfit1() - getTotalExpensesAmount();

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div>
      <Header />
      <main className="admin-content">
        <div className="summary">
          <h2>Summary for {filter.replace('-', ' ')}</h2>
          <h2>Total Cost Price: ₦{getTotalCostPrice().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
          <h2>Total Sale Price: ₦{getTotalSalePrice().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
          <h2>Total Expenses: ₦{getTotalExpensesAmount().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
          <h2>Total Profit: ₦{getTotalProfit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h2>
        </div>

        <h1>Sales Records</h1>
        <div className="filter-options">
          <button onClick={() => setFilter('today')}>Today</button>
          <button onClick={() => setFilter('this-week')}>This Week</button>
          <button onClick={() => setFilter('this-month')}>This Month</button>
          <button onClick={() => setFilter('custom')}>Custom</button>
        </div>
        {filter === 'custom' && (
          <div className="date-picker">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
            />
          </div>
        )}
        <div className="table-container">
          <table className="sales-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Product ID</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Quantity Before</th>
                <th>Quantity After</th>
                <th>Date</th>
                <th>Cost Price</th>
                <th>Sale Price</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {filteredSales.map((sale) => (
                <tr key={sale.id}>
                  <td>{sale.id}</td>
                  <td>{sale.product_id}</td>
                  <td>{sale.product_name}</td>
                  <td>{sale.quantity_bought}</td>
                  <td>{sale.quantity_before}</td>
                  <td>{sale.quantity_after}</td>
                  <td>{new Date(sale.date).toLocaleString()}</td>
                  <td>₦{parseFloat(sale.cost_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  <td>₦{parseFloat(sale.sale_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  <td>₦{parseFloat(sale.profit).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="10"><b>Total</b></td>
              </tr>
            </tfoot>
          </table>
        </div>

        <h1>Expenses Records</h1>
        <div className="table-container">
          <table className="sales-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredExpenses.map((expense) => (
                <tr key={expense.id}>
                  <td>{expense.id}</td>
                  <td>{expense.description}</td>
                  <td>{expense.amount}</td>
                  <td>{new Date(expense.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default SalesRecordsPage;
