import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';

const ProductListPage = () => {
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [cart, setCart] = useState(() => JSON.parse(localStorage.getItem('cart')) || []);
  const [addedProducts, setAddedProducts] = useState(() => {
    const storedAddedProducts = JSON.parse(localStorage.getItem('addedProducts')) || {};
    return products.reduce((acc, product) => {
      acc[product._id] = storedAddedProducts[product._id] || false;
      return acc;
    }, {});
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pin, setPin] = useState(['', '', '', '']);
  const [isPinValid, setIsPinValid] = useState(false);
  const [showPinModal, setShowPinModal] = useState(true);
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  useEffect(() => {
    if (isPinValid) {
      fetch('https://gidadobrothers.onrender.com/api/products')
        .then((res) => res.json())
        .then((data) => {
          setProducts(data);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [isPinValid]);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    localStorage.setItem('addedProducts', JSON.stringify(addedProducts));
  }, [addedProducts]);

  const handleQuantityChange = (productId, value) => {
    setQuantity((prevState) => ({
      ...prevState,
      [productId]: parseFloat(value) || 0
    }));
  };

  const addToCart = (productId) => {
    const product = products.find((p) => p._id === productId);
    const productQuantity = quantity[productId] || 1;

    const existingProduct = cart.find((item) => item._id === productId);
    if (existingProduct) {
      setCart(cart.map((item) =>
        item._id === productId ? { ...item, quantity: item.quantity + productQuantity } : item
      ));
    } else {
      setCart([...cart, { ...product, quantity: productQuantity }]);
    }
    setAddedProducts((prev) => ({
      ...prev,
      [productId]: true
    }));
  };

  const handlePinSubmit = () => {
    const enteredPin = pin.join('');
    console.log("Entered PIN:", enteredPin); // Debugging step
    if (enteredPin === '0000') {
      setIsPinValid(true);
      setShowPinModal(false);
    } else {
      alert(`Incorrect PIN. Please try again!`);
      setPin(['', '', '', '']); // Clear the PIN if incorrect
      inputRefs.current[0].focus(); // Focus back on the first input
    }
  };

  const handlePinChange = (value, index) => {
    if (value.length > 1) return; // Prevent more than one character input

    const newPin = [...pin];
    newPin[index] = value;

    setPin(newPin);

    // Move to the next input field if this one is filled and it's not the last field
    if (value && index < 3 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product._id.toString().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {showPinModal && (
        <div className="modal-overlay2">
          <div className="modal-content2">
            <h2>Enter PIN to Access</h2>
            <div className="inventory-form2">
              {pin.map((digit, index) => (
                <input
                  key={index}
                  type="password"
                  value={digit}
                  onChange={(e) => handlePinChange(e.target.value, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  maxLength="1"
                  className="inventory-form2"
                  autoFocus={index === 0}
                />
              ))}
            </div>
            <button onClick={handlePinSubmit} className="pin-submit-btn">
              Submit
            </button>
          </div>
        </div>
      )}

      {!showPinModal && (
        <>
          <header>
            <img src="logo.png" alt="Logo" className="logo" />
            <h3>Textile Store</h3>
            <nav>
              <Link to="/">Home</Link>
              <button onClick={() => navigate('/cart')}>View Cart</button>
            </nav>
          </header>
          <main className="admin-content">
            <h1>Products List</h1>
            <div className="search-bar">
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="text"
                placeholder="Search by name or ID"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {isLoading ? (
              <p>Loading products...</p>
            ) : (
              <div className="product-grid">
                {filteredProducts.map((product) => (
                  <div key={product._id} className="product-card">
                    <div className="product-header">
                      <h2>{product.name}</h2>
                      {addedProducts[product._id] ? (
                        <span className="added-text">Added</span>
                      ) : (
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          className="cart-icon"
                          onClick={() => addToCart(product._id)}
                        />
                      )}
                    </div>
                    <p>ID: {product._id}</p>
                    <p>Quantity in Stock: {product.quantity}</p>
                    <div className="quantity-control">
                      <button
                        className="quantity-btn"
                        onClick={() => handleQuantityChange(product._id, (quantity[product._id] || 1) - 1)}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        value={quantity[product._id] || 1}
                        onChange={(e) => handleQuantityChange(product._id, e.target.value)}
                        className="quantity-input"
                        min="0.01"
                        step="0.01"
                      />
                      <button
                        className="quantity-btn"
                        onClick={() => handleQuantityChange(product._id, (quantity[product._id] || 1) + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </main>
        </>
      )}
    </div>
  );
};

export default ProductListPage;
