import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductListPage from './pages/ProductListPage';
import ProductDetailsPage from './pages/admin/ProductDetailsPage';
import CartPage from './pages/CartPage';
import InventoryPage from './pages/admin/InventoryPage';
import ReceiptPage from './pages/receipt';
import PurchaseRecordsPage from './pages/admin/report';
import SalesRecordsPage from './pages/admin/report';
import LoginPage from './pages/admin/admin';
import Dashboard from './pages/admin/dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Expenses from './pages/admin/expenses';

function App() {
  
  return (
    <Router   >
      <Routes>
        <Route path="/" element={<ProductListPage />} />
        <Route path="product/:id" element={<ProductDetailsPage />} />
        <Route path="cart" element={<CartPage />} />
        <Route path="admin" element={<LoginPage />} />
        <Route path="inventory" element={<InventoryPage />} />
        <Route path="receipt" element={<ReceiptPage />} />
        <Route path="product/:id" element={<ProductDetailsPage />} />
        <Route path="/admin/report" element={<SalesRecordsPage />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/expenses" element={<Expenses />} />
      </Routes>
    </Router>
  );
}

export default App;

//basename={"/textiles"}
