import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './footer';

const Dashboard = () => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [modelOpen, setModelOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [totalCostPrice, setTotalCostPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Set loading state to false initially
  const navigate = useNavigate();

  const handleDone = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const newProduct = { name, price, quantity };
    console.log('Sending product data:', newProduct);
    try {
      const response = await fetch('https://gidadobrothers.onrender.com/api/products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newProduct),
      });
      const data = await response.json();
      console.log('Product added:', data);
      alert('Product added successfully!');
      setModelOpen(false);
      fetchProducts();
    } catch (error) {
      console.error('Error adding product:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchProducts = async () => {
    console.log('Fetching products...');
    setIsLoading(true); // Set loading state to true when starting the fetch
    try {
      const response = await fetch('https://gidadobrothers.onrender.com/api/products');
      const data = await response.json();
      console.log('Products fetched:', data);
      setProducts(data);
      const totalCost = data.reduce((sum, product) => sum + (parseFloat(product.price) * parseFloat(product.quantity)), 0);
      setTotalCostPrice(totalCost);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false); // Set loading state to false once fetch is done
    }
  };
  
  useEffect(() => {
    fetchProducts();
  }, []);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product._id.toString().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-dashboard">
      <Header />
      <main className="admin-content">
        <h2>Welcome, Admin!</h2>
        <div className="total-cost-price">
          <h3>Total Cost Price: ₦{totalCostPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
        </div>
        <button onClick={() => { setModelOpen(true); }}>Add Product</button>
        {modelOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h1>Add Product</h1>
              <button className='modal-close' onClick={() => { setModelOpen(false); }}><h3><b>X</b></h3></button>
              <form className="inventory-form" onSubmit={handleDone}>
                <input
                  type="text"
                  placeholder="Product Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <input
                  type="number"
                  placeholder="Product Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
                <input
                  type="number"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  required
                />
                <button type="submit" disabled={isLoading}>
                  {isLoading ? 'Adding...' : 'Add Product'}
                </button>
              </form>
            </div>
          </div>
        )}
        <div className="search-bar">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            placeholder="Search by name or ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <h1>Products List</h1>
        {isLoading ? ( // Display "Loading products..." sign when fetching products
          <p>Loading products...</p>
        ) : (
          <div className="product-grid">
            {filteredProducts.map((product) => (
              <div key={product._id} className="product-card" onClick={() => navigate(`/product/${product._id}`)}>
                <div className="product-header">
                  <h2>{product.name}</h2>
                </div>
                <p>ID: {product._id}</p>
                <p>Quantity in Stock: {product.quantity}</p>
                <p>Cost price: ₦{parseFloat(product.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
            ))}
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Dashboard;
