import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./styles.css";

const CartPage = () => {
  const navigate = useNavigate();
  
  const [cart, setCart] = useState(() =>
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [salePrices, setSalePrices] = useState(() => {
    const prices = {};
    cart.forEach((item) => {
      prices[item._id] = item.price * item.quantity;
    });
    return prices;
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const updateQuantity = (_id, quantity) => {
    setCart(
      cart.map((item) =>
        item._id === _id ? { ...item, quantity: Math.max(quantity, 1) } : item
      )
    );
  };

  const removeItem = (_id) => {
    setCart(cart.filter((item) => item._id !== _id));
  };

  const handlePurchase = async () => {
    if (isNaN(totalPrice) || totalPrice <= 0) {
      alert('Invalid total price. Please check your cart.');
      return;
    }

    setIsButtonDisabled(true);
    setIsLoading(true);
    try {
      const response = await fetch('https://gidadobrothers.onrender.com/api/purchase/confirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cart.map(item => ({
          _id: item._id,
          quantity: parseFloat(item.quantity),
          price: salePrices[item._id] / item.quantity,
        }))),
      });
  
      if (response.ok) {
        const receipt = await response.json();
        setCart([]);
        localStorage.removeItem('cart');
        navigate('/receipt', { state: { receipt: cart.map(item => ({ ...item, salePrice: salePrices[item._id] })) } });
      } else {
        console.error('Error processing purchase:', response.statusText);
        alert('Failed to process purchase.');
        setIsButtonDisabled(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error sending purchase request:', error);
      alert('Failed to process purchase.');
      setIsButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const totalPrice = cart.reduce((total, item) => {
    const itemTotal = salePrices[item._id];
    return total + itemTotal;
  }, 0);

  return (
    <div>
      <header>
        <img src="logo.png" alt="Logo" className="logo" />
        <h2>Textile store</h2>
        <nav>
          <Link to="/">Home</Link>
          <button onClick={() => navigate('/cart')}>View Cart</button>
        </nav>
      </header>
      <main className="admin-content">
      <h1>Shopping Cart</h1>
      {cart.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <>
          <div className="cart-grid">
            {cart.map((item) => (
              <div key={item._id} className="cart-item">
                <h2>{item.name}</h2>
                <p>ID: {item._id}</p>
                <div className="quantity-control">
                  <button
                    className="quantity-btn"
                    onClick={() => updateQuantity(item._id, item.quantity - 1)}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      updateQuantity(item._id, parseFloat(e.target.value) || 1)
                    }
                    className="quantity-input"
                  />
                  <button
                    className="quantity-btn"
                    onClick={() => updateQuantity(item._id, item.quantity + 1)}
                  >
                    +
                  </button>
                </div>
                <b>Sale Price</b>
                <input
                  type="number"
                  onChange={(e) =>
                    setSalePrices({
                      ...salePrices,
                      [item._id]: parseFloat(e.target.value)
                    })
                  }
                  className="quantity-input"
                />
                <button
                  className="remove-btn"
                  onClick={() => removeItem(item._id)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          <div className="invoice">
            <table>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Price per unit</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item) => {
                  const pricePerUnit = salePrices[item._id] / item.quantity;
                  const itemTotal = salePrices[item._id];
                  return (
                    <tr key={item._id}>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                      <td>{pricePerUnit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{itemTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="total-price">
              Total: <b>{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
            </div>
            <button
              className="purchase-btn"
              onClick={handlePurchase}
              disabled={isButtonDisabled || isNaN(totalPrice) || totalPrice <= 0}
            >
              {isLoading ? 'Processing...' : 'Confirm Purchase'}
            </button>
          </div>
        </>
      )}
      </main>
    </div>
  );
};

export default CartPage;
