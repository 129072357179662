// Footer.js
import React from 'react';
import './header.css';

const Footer = () => {
  return (
    <footer className="admin-footer">
      <p>&copy; 2024 Textile Store. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
