// Header.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authenticated');
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`admin-header ${menuOpen ? 'nav-open' : ''}`}>
      <div className="header-logo">
        <img src="logo.png" alt="Logo" className="logo" />
        <h1>Admin Dashboard</h1>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        &#9776;
      </button>
      <nav>
        <ul>
          <li><a href="/admin/dashboard">Home</a></li>
          <li><a href="/admin/report">Reports</a></li>
          <li><a href="/admin/expenses">Expenses</a></li>
          <li><button onClick={handleLogout}>Logout</button></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
