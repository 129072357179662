import React, { useEffect, useState } from "react";
import Header from "./Header";
import '../styles.css';
import './report.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isWithinInterval } from 'date-fns';

const Expenses = () => {
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [modal, setModal] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [filter, setFilter] = useState('all');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle button disable

  const fetchExpenses = async () => {
    try {
      const response = await fetch('https://gidadobrothers.onrender.com/api/expenses');
      const data = await response.json();
      setExpenses(data);
    } catch (error) {
      console.log('Fetching error:', error);
    }
  }

  useEffect(() => {
    fetchExpenses();
  }, []);

  useEffect(() => {
    filterExpenses();
  }, [expenses, filter, startDate, endDate]);

  const filterExpenses = () => {
    let filtered = expenses;

    switch (filter) {
      case 'today':
        filtered = filtered.filter(expense => {
          const expenseDate = new Date(expense.created_at);
          const today = new Date();
          return (
            expenseDate.getDate() === today.getDate() &&
            expenseDate.getMonth() === today.getMonth() &&
            expenseDate.getFullYear() === today.getFullYear()
          );
        });
        break;
      case 'this-week':
        filtered = filtered.filter(expense => {
          const expenseDate = new Date(expense.created_at);
          const startOfWeek = new Date();
          startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(endOfWeek.getDate() + 6);
          return isWithinInterval(expenseDate, { start: startOfWeek, end: endOfWeek });
        });
        break;
      case 'this-month':
        filtered = filtered.filter(expense => {
          const expenseDate = new Date(expense.created_at);
          const today = new Date();
          return (
            expenseDate.getMonth() === today.getMonth() &&
            expenseDate.getFullYear() === today.getFullYear()
          );
        });
        break;
      case 'custom':
        filtered = filtered.filter(expense => {
          const expenseDate = new Date(expense.created_at);
          return isWithinInterval(expenseDate, { start: startDate, end: endDate });
        });
        break;
      default:
        break;
    }

    // Sort filtered expenses from latest to oldest
    filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    setFilteredExpenses(filtered);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button after click

    const newExpense = { description, amount };
    try {
      const response = await fetch('https://gidadobrothers.onrender.com/api/expenses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newExpense),
      });

      if (response.ok) {
        alert('Successful');
        setDescription('');
        setAmount('');
        setModal(false);
        fetchExpenses();
      } else {
        alert('Error: ' + response.statusText);
      }
    } catch (error) {
      console.log('Error:', error);
      alert('Error: ' + error.message);
    } finally {
      setIsSubmitting(false); // Re-enable the button after submission completes
    }
  };

  const getTotalAmount = () => {
    if (filteredExpenses.length === 0) {
      return 0;
    }
    return filteredExpenses.reduce((total, expense) => total + parseFloat(expense.amount), 0);
  };

  return (
    <div>
      <Header />
      <main className="admin-content">
        <button onClick={() => setModal(true)}>Add Expenses</button>

        {/* Modal */}
        {modal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button onClick={() => setModal(false)} className='modal-close'><b>X</b></button>
              <h1>Expenses Details</h1>
              <form className="inventory-form" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <input
                  type="number"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Confirm'}
                </button>
              </form>
            </div>
          </div>
        )}

        {/* Filter options */}
        <div className="summary">
          <p><b>Total Expenses ₦{getTotalAmount().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b></p>
        </div>
        <div className="filter-options">
          <button onClick={() => setFilter('all')}>All</button>
          <button onClick={() => setFilter('today')}>Today</button>
          <button onClick={() => setFilter('this-week')}>This Week</button>
          <button onClick={() => setFilter('this-month')}>This Month</button>
          <button onClick={() => setFilter('custom')}>Custom</button>
        </div>

        {/* Custom date range picker */}
        {filter === 'custom' && (
          <div className="date-picker">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
            />
          </div>
        )}

        {/* Expenses table */}
        <h1>Expenses Report</h1>
        <div className="table-container">
          <table className="sales-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredExpenses.map((expense) => (
                <tr key={expense._id}>
                  <td>{expense._id}</td>
                  <td>{expense.description}</td>
                  <td>{expense.amount}</td>
                  <td>{new Date(expense.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2"><b>Total</b></td>
                <td>₦{getTotalAmount().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </main>
    </div>
  );
};

export default Expenses;
