import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './styles.css'; // Make sure to include styles for your page
import Header from './Header';

const ProductDetailsPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [isEditingQuantity, setIsEditingQuantity] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      const response = await fetch(`https://gidadobrothers.onrender.com/api/products/${id}`);
      const data = await response.json();
      setProduct(data);
      setName(data.name);
      setPrice(data.price);
      setQuantity(data.quantity);
    };

    fetchProduct();
  }, [id]);

  const handleSave = async (event) => {
    event.preventDefault();
    const updatedProduct = { name, price, quantity };

    await fetch(`https://gidadobrothers.onrender.com/api/products/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedProduct),
    });

    navigate('/admin/dashboard'); // Navigate back to the dashboard or a different page after saving
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this product?');
    if (confirmDelete) {
      try {
        const response = await fetch(`https://gidadobrothers.onrender.com/api/products/${id}`, {
          method: 'DELETE',
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        console.log(data.msg); // Log the response message
        alert('Product deleted successfully')
        navigate('/admin/dashboard'); // Navigate back to the dashboard after deletion
      } catch (error) {
        console.error('Failed to delete product:', error);
      }
    }
  };
  

  if (!product) return <div>Loading...</div>;

  return (
    <div>
      <Header />
      <main className="admin-content">
        <h1>Product Details</h1>
        <form onSubmit={handleSave}>
          <div className="field">
            <label>Name:</label>
            {isEditingName ? (
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            ) : (
              <span>{name}</span>
            )}
            <button
              type="button"
              onClick={() => setIsEditingName(!isEditingName)}
              className="edit-button"
            >
              {isEditingName ? 'Save' : 'Edit Name'}
            </button>
          </div>
          <div className="field">
            <label>Price:</label>
            {isEditingPrice ? (
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            ) : (
              <span>{price}</span>
            )}
            <button
              type="button"
              onClick={() => setIsEditingPrice(!isEditingPrice)}
              className="edit-button"
            >
              {isEditingPrice ? 'Save' : 'Edit Price'}
            </button>
          </div>
          <div className="field">
            <label>Quantity:</label>
            {isEditingQuantity ? (
              <div className="quantity-control">
                <button
                  type="button"
                  className="quantity-btn"
                  onClick={() => setQuantity((prevQuantity) => prevQuantity - 1)}
                >
                  -
                </button>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(parseFloat(e.target.value))}
                  className="quantity-input"
                  min="0.01"
                  step="0.01"
                />
                <button
                  type="button"
                  className="quantity-btn"
                  onClick={() => setQuantity((prevQuantity) => prevQuantity + 1)}
                >
                  +
                </button>
              </div>
            ) : (
              <span>{quantity}</span>
            )}
            <button
              type="button"
              onClick={() => setIsEditingQuantity(!isEditingQuantity)}
              className="edit-button"
            >
              {isEditingQuantity ? 'Save' : 'Edit Quantity'}
            </button>
          </div>
          {(isEditingName || isEditingPrice || isEditingQuantity) && (
            <button type="submit">Save All Changes</button>
          )}
        </form>
        <button onClick={handleDelete} className="delete-button">
          Delete Product
        </button>
      </main>
    </div>
  );
};

export default ProductDetailsPage;
